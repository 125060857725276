import React from "react"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import "../reset.css"

require("../../static/fonts.css")

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  :root {
    font-size: 100%; /* 16px equivalent */

    --primaryColour: #D19488;
    --primaryColourFade: rgba(209, 148, 136, 0.5);
    --primaryColourFeint: #FFD7D9;
    --secondaryColour: #96BFCB;
    --tertiaryColour: #FFF1E7;
    --tertiaryColourFade: rgba(255, 241, 231, 0.5);
    --warningColour: #FF8A73;

    /* Needs customising */
    --gridColumnCount: 6;
    --gridColumnGap: calc(12 / 1280 * 100vw);
    --gridRowGap: var(--gridColumnGap);
    --gridMarginGapRelative: calc( 48 / 1280 );
    --gridMarginGap: calc(var(--gridMarginGapRelative) * 100vw);
    /* --gridMarginGap: 24px; */
    --gridTemplateColumnsDefault: repeat( var(--gridColumnCount), minmax(0,1fr) );

    @media screen and (min-width: 1024px){
      --gridColumnCount: 12;
    }
  }
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-font-smoothing: antialiased;
    -webkit-font-smoothing: antialias !important;
    -moz-font-smoothing: antialias !important;
    font-smoothing: antialias !important;
  }
  body {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    height: 100%;
    background-color: var(--tertiaryColour);
    color: var(--primaryColour);
  }
  #___gatsby {
    height: 100%;
  }
  #gatsby-focus-wrapper {
    height: 100%;
  }

  ::selection {
    background: var(--secondaryColour); /* WebKit/Blink Browsers */
    color: var(--tertiaryColour);
  }
  ::-moz-selection {
    background: var(--secondaryColour); /* Gecko Browsers */
    color: var(--tertiaryColour);
  }
`
const Wrapper = styled.div`
  position: relative;
  min-height: 600px;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
`

export default function Layout({ children }) {
  return (
    <Wrapper>
      <Helmet>
        <html lang="en" />
        <meta name="theme-color" content="#96BFCB" />
      </Helmet>
      <GlobalStyle />
      {children}
    </Wrapper>
  )
}
